import React, { Component } from 'react'
import axios from 'axios'

class SearchIFSC extends Component {


  constructor(props) {
    super(props)

    this.state = {
      ifsccode: '',
      bankname: '',
      bankbranch: ''
    }

    this.callIFSCAPI = this.callIFSCAPI.bind(this)
    this.IFSCTextChange = this.IFSCTextChange.bind(this)
  }

  IFSCTextChange = (event) => {
    this.setState({
      ifsccode: event.target.value
    })
  }

  callIFSCAPI() {


    if (this.state.ifsccode != "") {
      axios.get('https://svn.symapp.in/razor-pay-ifsc/api.php?ifsc=' + this.state.ifsccode)
        .then(response => {
          console.log(response)
          if (response.data.success == "1") {
            this.setState({
              bankname: response.data.bankname,
              bankbranch: response.data.bankbranch
            })
          }
          else {
            console.log("Error")
            this.setState({
              ifsccode:'',
              bankname: '',
              bankbranch: ''
            })
            alert(response.data.message);
          }

        })
        .catch(error => {

        })
    }
    else{
      alert("Please Enter IFSC Code");
    }
  }

  //Called When Component Mounts and is Executed only Once
  componentDidMount() {

  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-default navbar-inverse navbar-theme navbar-theme-abs navbar-theme-transparent navbar-theme-border" id="main-nav">
          <div className="container">
            <div className="navbar-inner nav">
              <div className="navbar-header">
                <a className="navbar-brand" href="index.html">
                  <img src="img/razorpay-logo-white.svg" alt="Image Alternative text" title="Image Title" />
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="_pos-r">
          <div className="theme-hero-area _h-desk-100vh">
            <div className="theme-hero-area-bg-wrap">
              <div className="theme-hero-area-bg backgroundImageDivOne"></div>
              <div className="theme-hero-area-bg-pattern theme-hero-area-bg-pattern-strong backgroundImageDivTwo"></div>
              <div className="theme-hero-area-mask"></div>
              <div className="theme-hero-area-inner-shadow"></div>
            </div>
            <div className="theme-hero-area-body _pos-desk-v-c _w-f _pv-mob-50">
              <div className="container">
                <div className="theme-search-area-tabs _pb-100">
                  <div className="theme-search-area-tabs-header _c-w _mb-50">
                    <h6 className="theme-search-area-tabs-title">Search For Bank Name and Branch</h6>
                  </div>
                  <div className="tabbable">
                    <div className="tab-content _pt-15">
                      <div className="tab-pane active" id="SearchAreaTabs-1" role="tab-panel">
                        <div className="theme-search-area theme-search-area-white">
                          <div className="theme-search-area-form">
                            <div className="row" data-gutter="30">
                              <div className="col-md-3 ">
                                <div className="theme-search-area-section first theme-search-area-section-line">
                                  <div className="theme-search-area-section-inner">
                                    <input className="theme-search-area-section-input" onChange={this.IFSCTextChange} type="text" placeholder="Enter IFSC No" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-1 ">
                                <button onClick={this.callIFSCAPI} className="theme-search-area-submit _mt-0 _fs-xl theme-search-area-submit-curved theme-search-area-submit-primary theme-search-area-submit-glow">&rarr;</button>
                              </div>
                              <div className="col-md-8 ">
                                <div className="row" data-gutter="30">
                                  <div className="col-md-6 ">
                                    <div className="theme-search-area-section theme-search-area-section-line">
                                      <div className="theme-search-area-section-inner">
                                        <input className="theme-search-area-section-input textcolor"
                                          value={this.state.bankname} disabled placeholder="Bank Name Here" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-6 ">
                                    <div className="theme-search-area-section theme-search-area-section-line">
                                      <div className="theme-search-area-section-inner">
                                        <input className="theme-search-area-section-input textcolor" value={this.state.bankbranch} disabled placeholder="Bank Branch Here" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="theme-search-area-options _mob-h clearfix">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="theme-footer-abs">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <p className="theme-footer-abs-copyright">Copyright &copy;
                      <a href="http://www.frenztech.com" target="_blank">Frenztech</a>. All rights reserved.
                    </p>
                  </div>
                  <div className="col-md-6">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SearchIFSC;